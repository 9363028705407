import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
  AppBar,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Dashboard,
  Group,
  Payment,
  ExitToApp,
  People,
  MonetizationOn,
  Menu as MenuIcon,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/Ajooo_Logo.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const drawerWidth = 240;

const SidebarLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const nav = useNavigate();

  // Authentication check
  useEffect(() => {
    const user = localStorage.getItem("user");
    const data = JSON.parse(user);
    if (!user || data?.usertype !== "Admin") {
      nav("/");
    }
  }, [nav]);

  const menuItems = [
    { text: "Dashboard", icon: <Dashboard />, path: "/dashboard" },
    { text: "Users", icon: <People />, path: "/users" },
    { text: "Groups", icon: <Group />, path: "/groups" },
    { text: "Payments", icon: <Payment />, path: "/payment" },
    {
      text: "Payments Received",
      icon: <MonetizationOn />,
      path: "/payment-received",
    },
    { text: "Logout", icon: <ExitToApp /> },
  ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    nav("/"); // Redirect to login page
  };

  // Drawer content
  const drawer = (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <img
        src={Logo}
        alt="logo"
        style={{ width: "80px", margin: "20px auto", display: "block" }}
      />
      <List sx={{ flexGrow: 1 }}>
        {menuItems.slice(0, -1).map((item, index) => (
          <ListItem
            button
            component={Link}
            to={item.path}
            key={index}
            sx={{
              color: "#ffff",
              "&:hover": {
                backgroundColor: "#CB76F9",
              },
            }}
          >
            <ListItemIcon sx={{ color: "#ffff" }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>

      {/* Logout button */}
      <ListItem
        button
        onClick={handleLogout}
        sx={{
          color: "#ffff",
          "&:hover": {
            backgroundColor: "#CB76F9",
          },
          marginBottom: "20px",
        }}
      >
        <ListItemIcon sx={{ color: "#ffff" }}>
          {menuItems[menuItems.length - 1].icon}
        </ListItemIcon>
        <ListItemText primary={menuItems[menuItems.length - 1].text} />
      </ListItem>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      {/* AppBar for mobile with a hamburger menu */}
      {isMobile && (
        <AppBar position="fixed" sx={{ backgroundColor: "#52057B" }}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              <img
                src={Logo}
                alt="logo"
                style={{ width: "70px", display: "block" }}
              />
            </Typography>
          </Toolbar>
        </AppBar>
      )}

      {/* Drawer for desktop or mobile */}
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? mobileOpen : true}
        onClose={handleDrawerToggle}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "#52057B",
          },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile
        }}
      >
        {drawer}
      </Drawer>

      {/* Main content area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginLeft: isMobile ? 0 : `${drawerWidth}px`,
        }}
      >
        {/* Toolbar offset for mobile AppBar */}
        {isMobile && <Toolbar />}
        {/* Content goes here */}
      </Box>
    </Box>
  );
};

export default SidebarLayout;
