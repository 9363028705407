import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Grid, Icon, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  Diversity1,
  Diversity3,
  AttachMoney,
  People,
} from "@mui/icons-material";

const useStyles = makeStyles({
  card: {
    position: "relative",
    minWidth: "275px",
    borderRadius: "10px",
    boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
    overflow: "visible",
    "&:hover": {
      boxShadow: "0 6px 10px rgba(0, 0, 0, 0.3)",
    },
  },
  iconContainer: {
    position: "absolute",
    top: "-20px",
    left: "20px",
    backgroundColor: "#fff",
    borderRadius: "10px",
    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.16)",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconStyle: {
    color: "#fff",
    fontSize: "2rem",
  },
  cardContent: {
    textAlign: "right",
    paddingTop: "20px",
  },
  categoryText: {
    fontSize: "0.9rem",
    color: "#999",
  },
  valueText: {
    fontSize: "2rem",
    fontWeight: "bold",
  },
});

const Cards = () => {
  const classes = useStyles();

  const [cards, setCards] = useState([
    {
      item: 1,
      icon: <People />,
      category: "Users",
      value: 47,
      currentCount: 0,
      gradient: "linear-gradient(60deg, #9c27b0, #7b1fa2)",
    },
    {
      item: 2,
      icon: <Diversity1 />,
      category: "Total Groups",
      value: 21,
      currentCount: 0,
      gradient: "linear-gradient(60deg, #ffa726, #fb8c00)",
    },
    {
      item: 3,
      icon: <Diversity3 />,
      category: "Active Groups",
      value: 12,
      currentCount: 0,
      gradient: "linear-gradient(60deg, #66cdaa, #2e8b57)",
    },
    {
      item: 4,
      icon: <AttachMoney />,
      category: "Amount",
      value: 1695,
      currentCount: 0,
      gradient: "linear-gradient(60deg, #ef5350, #e53935)",
    },
  ]);

  useEffect(() => {
    animateCounts();
  }, []);

  const animateCounts = () => {
    cards.forEach((card) => {
      animateCount(card, card.value);
    });
  };

  const animateCount = (card, value) => {
    let count = 0;
    const step = Math.ceil(value / 30);
    const interval = setInterval(() => {
      count += step;
      if (count >= value) {
        setCards((prevCards) =>
          prevCards.map((c) =>
            c.item === card.item ? { ...c, currentCount: value } : c
          )
        );
        clearInterval(interval);
      } else {
        setCards((prevCards) =>
          prevCards.map((c) =>
            c.item === card.item ? { ...c, currentCount: count } : c
          )
        );
      }
    }, 50);
  };

  return (
    <Box
      sx={{
        background: "#c47ceb",
        height: "100vh",
        marginLeft: "-5px",
      }}
    >
      <Grid container spacing={2} pl={2} pr={2} pt={2}>
        {cards.map((item, index) => (
          <Grid item xs={12} sm={4} lg={4} key={index} mt={2}>
            <Card className={classes.card}>
              <div
                className={classes.iconContainer}
                style={{ background: item.gradient }}
              >
                <Icon className={classes.iconStyle}>{item.icon}</Icon>
              </div>
              <CardContent className={classes.cardContent}>
                <Typography className={classes.categoryText} variant="body2">
                  {item.category}
                </Typography>
                <Typography className={classes.valueText}>
                  {item.currentCount}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Cards;
