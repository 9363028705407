import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
} from "@mui/material";

import { getData } from "../services/Request";
import { useLoader } from "../context/LoaderContext";
import { useParams } from "react-router-dom";

const GroupDetail = () => {
  const { showLoader, hideLoader } = useLoader();
  const [groupDetail, setGroupDetail] = useState();
  const { id } = useParams();
  const [selectedMpesaDetails, setSelectedMpesaDetails] = useState(null);

  useEffect(() => {
    showLoader();
    getData(`group/detail/${id}`)
      .then((response) => {
        setGroupDetail(response);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        hideLoader();
      });
  }, []);
  const handleRowClick = (mpesaDetails) => {
    setSelectedMpesaDetails(mpesaDetails);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: "#52057B !important",
              }}
            >
              <TableCell sx={{ color: "#ffffff" }}>Group Name</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Status</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Amount</TableCell>
              <TableCell sx={{ color: "#ffffff" }}>Duration</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{groupDetail?.groupPlan?.groupName}</TableCell>
              <TableCell>{groupDetail?.groupPlan?.status}</TableCell>
              <TableCell>{groupDetail?.groupPlan?.amount}</TableCell>
              <TableCell>{groupDetail?.groupPlan?.duration}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#52057B !important",
                  }}
                >
                  <TableCell sx={{ color: "#ffffff" }}>Name</TableCell>
                  <TableCell sx={{ color: "#ffffff" }}>
                    Collection Date
                  </TableCell>
                  <TableCell sx={{ color: "#ffffff" }}>Deposit Date</TableCell>
                  <TableCell sx={{ color: "#ffffff" }}>
                    Deposit Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupDetail?.groupDetails?.map((row, index) => (
                  <TableRow
                    key={row.id}
                    hover
                    onClick={() => handleRowClick(row.mpesaDetails)}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell>{row.userName}</TableCell>
                    <TableCell>{row.dateOfCollection}</TableCell>
                    <TableCell>{row.dateOfDeposit}</TableCell>
                    <TableCell
                      style={{
                        color: row.userDeposit === 0 ? "#C53035" : "#008000",
                        fontWeight: 700,
                      }}
                    >
                      {row.userDeposit === 0 ? "Not Send" : "Send Money"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: "#52057B !important",
                  }}
                >
                  <TableCell sx={{ color: "#ffffff" }}>Currency</TableCell>
                  <TableCell sx={{ color: "#ffffff" }}>
                    Payment Status
                  </TableCell>
                  <TableCell sx={{ color: "#ffffff" }}>
                    Transaction Id
                  </TableCell>
                  <TableCell sx={{ color: "#ffffff" }}>Date</TableCell>
                  <TableCell sx={{ color: "#ffffff" }}>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedMpesaDetails?.map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell>{row.currency}</TableCell>
                    <TableCell>{row.paymentStatus}</TableCell>
                    <TableCell>{row.transactionId}</TableCell>
                    <TableCell>{row.updatedAt}</TableCell>
                    <TableCell>{row.amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default GroupDetail;
