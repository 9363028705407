import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { LoaderProvider } from "./context/LoaderContext";
import Sidebar from "./components/Sidebar";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import Groups from "./pages/Groups";
import Payment from "./pages/Payment";
import PaymentReceived from "./pages/PaymentReceived";
import Loader from "./components/Loader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import GroupDetail from "./pages/GroupDetail";

function App() {
  const theme = useTheme();
  return (
    <LoaderProvider>
      <Router>
        <Loader />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={<LayoutWithSidebar page={<Dashboard />} />}
          />
          <Route
            path="/users"
            element={<LayoutWithSidebar page={<Users />} />}
          />
          <Route
            path="/groups"
            element={<LayoutWithSidebar page={<Groups />} />}
          />
          <Route
            path="/payment"
            element={<LayoutWithSidebar page={<Payment />} />}
          />
          <Route
            path="/payment-received"
            element={<LayoutWithSidebar page={<PaymentReceived />} />}
          />
          <Route
            path="/group-detail/:id"
            element={<LayoutWithSidebar page={<GroupDetail />} />}
          />
        </Routes>
      </Router>
    </LoaderProvider>
  );
}

const drawerWidth = 240;
// Layout with Sidebar for protected pages
const LayoutWithSidebar = ({ page }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Sidebar />
      {/* Main content area should have a margin-left equal to the sidebar's width */}
      <div
        style={{
          padding: "5px",
          marginLeft: isMobile ? 0 : `${drawerWidth}px`,
        }}
      >
        {page}
      </div>
    </div>
  );
};

export default App;
